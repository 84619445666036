<script setup lang="ts">
import type { Schemas } from "#shopware";
import {
  getProductRoute,
  getProductFromPrice,
  getTranslatedProperty,
} from "@shopware/helpers";
import { BadgeType } from "./ProductBadge.vue";
import placeholderImage from "~~/public/placeholder.webp";

const { t } = useI18n();
const localePath = useLocalePath();
const { getDisplayVariant } = useProductHelper();
const props = withDefaults(
  defineProps<{
    product: Schemas["Product"];
    layoutType?: BoxLayout;
    displayMode?: DisplayMode;
    isVisible?: boolean;
    displayAdditionalInfos?: boolean;
    autoWidth?: boolean;
  }>(),
  {
    layoutType: "standard",
    displayMode: "cover",
    isVisible: true,
    displayAdditionalInfos: false,
    autoWidth: false,
  },
);

const { user, isLoggedIn, refreshUser } = useUser();
const showInternalData = asyncComputed(async () => {
  if (!isLoggedIn.value) return false;

  if (!user.value?.group) await refreshUser({ associations: { group: {} } });
  const shouldShowInternal =
    user.value?.group?.customFields?.showInternalProperties ?? false;
  return shouldShowInternal;
}, false);

const { product } = toRefs(props);
const variant = computed(() => getDisplayVariant(product.value));

const { isInCart, count, addToCartWithNotifications } =
  await useAddToCart(variant);

const { toggleWishlistProduct, isInWishlist } =
  useProductWishlistExtended(variant);

const { toggleCompareListProduct, isInCompareList } =
  useProductCompareList(variant);

const { unitPrice, price } = useProductPrice(variant);

const { isBWare, isSet, salesPercentage, isActionPrice } =
  useProductState(variant);

const shouldShowNewBadge = computed(() => {
  const dateStr = variant.value.customFields?.newBadgeUntil;
  if (!dateStr) return false;
  const date = new Date(dateStr);
  return date > new Date();
});

const variantCount = computed(
  () => variant.value.customFields?.variantCount ?? 0,
);

const canBeAddedToCart = computed(() => {
  return (
    !getProductFromPrice(variant.value) && // I just moved this here; I have no idea what a 'from price' is
    (variant.value.availableStock || 0) > 0 &&
    !variant.value.productNumber.startsWith("SW.")
  );
});

const getCoverImage = (product: Schemas["Product"]) => {
  return product?.cover?.media || product?.media?.[0]?.media || null;
};
</script>

<template>
  <NuxtLink
    :to="localePath(getProductRoute(variant))"
    class="flex flex-col gap-4 bg-scheppach-shades-0 b-1 b-scheppach-primary-700 relative rd hover:b-scheppach-primary-700 pt-2 overflow-y-hidden"
    :class="[
      displayAdditionalInfos || showInternalData ? 'h-120' : 'h-110',
      autoWidth ? 'w-a' : 'w-85',
    ]"
  >
    <div class="absolute left-3">
      <div class="flex items-center gap-2 mt-1">
        <ProductBadge
          v-if="salesPercentage"
          :badge-type="BadgeType.SALE"
          :text="`${salesPercentage}% *`"
        />
        <ProductBadge
          v-if="isBWare"
          :badge-type="BadgeType.BWARE"
          :text="t('product.bStock')"
        />
        <ProductBadge
          v-if="isSet"
          :badge-type="BadgeType.IS_SET"
          :text="t('product.productSet')"
        />
        <ProductBadge
          v-if="isActionPrice"
          :badge-type="BadgeType.IS_ACTION"
          :text="t('product.actionPrice')"
        />
        <ProductBadge
          v-if="variantCount > 1"
          :badge-type="BadgeType.MORE_VARIANTS"
          :text="`${variantCount} ${t('product.xVariantsAvailable')}`"
        />
      </div>
    </div>

    <div class="absolute right-3">
      <div class="flex gap-2 flex-col cursor-pointer">
        <span
          class="inline-block w-6 h-6 hover:scale-115 transition duration-100 c-scheppach-primary-700"
          :class="[isInWishlist ? 'i-sl-love-it' : 'i-sl-love-it-alternate']"
          @click.prevent="toggleWishlistProduct"
        ></span>
        <span
          class="inline-block w-6 h-6 hover:scale-115 transition duration-100 c-scheppach-primary-700"
          :class="[
            isInCompareList
              ? 'i-sl-data-transfer-square-horizontal'
              : 'i-sl-synchronize-arrows-square-1',
          ]"
          @click.prevent="toggleCompareListProduct"
        ></span>
      </div>
    </div>

    <SharedMediaImage
      v-if="getCoverImage(variant)"
      :media="getCoverImage(variant)"
      :width="240"
      :height="200"
      alt="card"
      class="w-[240px] h-[200px] mx-auto object-contain mt-10"
    />
    <img
      v-else
      :src="placeholderImage"
      class="w-[240px] h-[200px] mx-auto object-contain mt-10"
    />
    <span
      v-if="shouldShowNewBadge"
      class="new-badge absolute top-60px inline-block c-white flex justify-center items-center font-bold uppercase"
    >
      {{ t("product.new") }}
    </span>

    <div
      class="inset-inline-0 bottom-0 lg:bottom-1 lg:bottom-3 m-3 flex-grow-1 flex flex-col"
    >
      <p class="mb-3 font-700 text-[16px] lh-tight line-clamp-2 mb-a">
        {{
          getTranslatedProperty(variant, "name") ||
          getTranslatedProperty(product, "name")
        }}
      </p>

      <div
        v-if="(variant.availableStock || 0) > 0 && unitPrice > 0"
        class="mt-2"
      >
        <span
          class="w-4 h4 inline-block v-mid i-sl-check-circle-1-alternate c-scheppach-success-500 mr-1"
        ></span>
        <span
          v-if="variant.deliveryTime?.translated?.name"
          class="v-mid text-xs md:text-sm"
        >
          {{ $t("product.shipping.in") }}
          {{ variant.deliveryTime?.translated?.name }}
        </span>
        <span v-else class="v-mid text-xs md:text-sm">
          {{ $t("product.shipping.possible") }}
          {{ variant.deliveryTime?.translated?.name }}
        </span>
      </div>
      <div v-else>
        <span
          class="w-4 h4 inline-block v-mid i-sl-delete-2 c-scheppach-error-500 mr-1"
        ></span>
        <span class="v-mid text-xs md:text-sm c-scheppach-error-500">
          {{ $t("errors.product-out-of-stock") }}
        </span>
      </div>
      <div
        v-if="displayAdditionalInfos || showInternalData"
        class="inline opacity-60"
      >
        <span class="inline-block line-clamp-2 mr-2">
          #: {{ variant.productNumber }}
        </span>
        <span class="inline-block line-clamp-2">EAN: {{ variant.ean }}</span>
      </div>

      <div class="flex justify-between items-center">
        <div v-if="unitPrice > 0" class="flex gap-2 items-center">
          <SharedPrice
            :value="unitPrice"
            class="font-700 text-[16px] lg:text-[20px] text-scheppach-primary-500"
          >
          </SharedPrice>
          <div class="flex gap-1">
            <SharedPrice
              v-if="price?.listPrice?.price"
              :value="price?.listPrice?.price"
              class="font-700 text-[14px] text-scheppach-neutral-500 line-through"
            >
              <template #afterPrice>**</template>
            </SharedPrice>
          </div>
        </div>
        <div v-else>&nbsp;</div>

        <button
          v-if="canBeAddedToCart && unitPrice > 0"
          type="button"
          class="flex justify-center p-2 b b-transparent shadow-sm text-sm rd text-white bg-scheppach-primary-500 hover:bg-scheppach-primary-700"
          :class="{
            'text-white bg-scheppach-primary-500 hover:bg-brand-primary':
              !isInCart,
            'text-white bg-scheppach-primary-700': isInCart,
          }"
          data-testid="add-to-cart-button"
          :aria-label="$t('product.addToCart')"
          @click.prevent="addToCartWithNotifications"
        >
          <span
            class="c-white i-sl-shopping-cart-empty-1 size-5 mx-auto"
          ></span>
          <div v-if="isInCart" class="flex ml-2">
            {{ count }}
          </div>
        </button>

        <div
          v-else
          class="flex justify-center p-2 b b-transparent shadow-sm text-sm rd text-white bg-scheppach-primary-500 hover:bg-scheppach-primary-700"
        >
          <span class="c-white i-sl-details-arrow size-5 mx-auto"></span>
        </div>
      </div>
      <div v-if="salesPercentage" class="c-gray-6 text-3 mt-2">
        {{ t("product.priceAdvantage") }}
      </div>
    </div>
  </NuxtLink>
</template>

<style>
.new-badge {
  padding: 0 10px;
  height: 34px;
  background-color: var(--scheppach-success-500);
}

.new-badge::after {
  content: "";
  border-color: var(--scheppach-success-500);
  border-right-color: transparent;
  border-top-width: 17px;
  border-bottom-width: 17px;
  border-right-width: 10px;
  top: 0px;
  position: absolute;
  right: -10px;
}
</style>
